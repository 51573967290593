import React, {  useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ncdf from "../utils/normal_dist";
import NormSInv from "../utils/inverse_normal_dist";
import Dashboard from "./Dashboard";
import Grid from "@material-ui/core/Grid";
import InputSummary from "./InputSummary";
import Paper from "@material-ui/core/Paper";
import InputChart from "./InputChart";
import OutputChart from "./OutputChart";
import { makeStyles } from '@material-ui/core/styles';
import EditableTable from './EditableTable'
import EditableTableOutput from './EditableTableOutput'
import Alert from "./Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { gridColumnLookupSelector } from "@material-ui/data-grid";
import {matrix, multiply, norm, transpose} from 'mathjs';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      paddingLeft:"5%",
      paddingRight:"5%"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.primary,
      background: "#1e375e",
      height: 325,
      borderRadius: '5px'
    },
    btn:{
        color:'#999999'
    }
  }));

export default function Layout(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const data = useSelector((state) => state?.inputs?.values);
    const genericInputs = useSelector((state) => state?.inputs?.genericInputs);
    const [inputData, setInputData] = useState(data)
    // const [inputSummary, setInputSummary]=useState(computeInputSummary(inputData))

    const outputData = runComputation(inputData);
    // const outputSummary = computeOutputSummary(outputData);

    useEffect(()=>{
        setInputData(data);
        // setInputSummary(computeInputSummary(data));
    },[data])

    const [errorMsg, setErrorMsg] = useState(null);

    function runComputation(data) {
        const {cf, corr, extremeImpactPct} = genericInputs;
        let impactArray = [];
        let logInvArray =[];
        
        const sigma = computeSigma(data[0].EI, data[0].TI, extremeImpactPct);
        const mu = computeMu(data[0].EI, extremeImpactPct, sigma);
        const opVaR = computeVaR(mu, sigma, cf, data[0].LH);
        
        const min = data[0].TI;
        const max = data[0].EI;
        const step = (max - min)/20;
        for(let i=0; i<21;i++){
            impactArray.push({impact: min+i*step, cdf: computeCDF(mu, sigma, min+i*step), 
                pdf:computePDF(mu, sigma, min+i*step) });
            logInvArray.push({prob:(i)*5/100, logInv: computeLogInv((i)*5/100, mu, sigma, cf)})
        }
        const outData = { impactArray, opVaR, logInvArray };
        console.log('Impact Array', outData)
        return outData;
    }
    return (
        <div className={classes.root}>
                <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <EditableTable data={inputData}/>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    
                    <Paper className={classes.paper}>
                    <Dashboard
                        data={outputData}
                        // inputSummary={inputSummary}
                        // outputSummary={outputSummary}
                    />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <InputChart
                            data={outputData?.impactArray}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                     <OutputChart
                        outData={outputData?.logInvArray}
                    />
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar
                open={errorMsg !== null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                autoHideDuration={5000}
                onClose={() => {
                    setErrorMsg(null);
                }}
            >
                <Alert severity="error">{errorMsg}</Alert>
            </Snackbar>
        </div>
    );
    }
function computePDF(mu, sigma, impact) {
    const temp = ((Math.log(impact)-mu)**2)/(2*sigma*sigma)
    const result = Math.exp(-temp)/(impact*sigma*Math.sqrt(2*Math.PI));
    return result;
}
function computeCDF(mu, sigma, impact) {
    return ncdf(Math.log(impact),mu,sigma);
}

function computeSigma(extremeImpact, typicalImpact, extremeImpactPct,){
    return(-NormSInv(extremeImpactPct)+ Math.sqrt(Math.pow(NormSInv(extremeImpactPct),2) + 
                                            4*(Math.log(extremeImpact)-Math.log(typicalImpact))))/2;
}
function computeMu(extremeImpact, extremeImpactPct, sigma){
    return Math.log(extremeImpact) - NormSInv(extremeImpactPct)*sigma;
}
function computeVaR(mu, sigma, cf, likelihood) {
    return Math.round(Math.exp(mu + sigma*NormSInv(1-(1-cf)/likelihood)));
}
function computeLogInv(prob, mu, sigma, cf){
    return Math.round(Math.exp(mu + sigma*NormSInv(1-(1-cf)/prob)));
}