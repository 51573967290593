import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createLogger } from "redux-logger";
import reduxFreeze from "redux-freeze";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import rootReducer from "./ducks";
import localforage from "localforage";
import throttle from "./utils/throttle";
import { Auth0Provider } from "@auth0/auth0-react";
import RiskScenario from "./components/RiskScenario"

let loadData = new Promise((resolve, reject) => {
  localforage
      .getItem("state")
      .then((data) => {
          resolve(data);
      })
      .catch((err) => {
          reject(err);
      });
});

loadData
    .then((data) => {
        let middleware = [reduxFreeze, createLogger()];
        let store = createStore(
            rootReducer,
            // {},
            data ? data : {},
            applyMiddleware(...middleware)
        );
        // initialize if ne
        if (!data) {
        }
        store.subscribe(
            throttle(() => {
                let state = { ...store.getState() };
                localforage.setItem("state", state);
            }, 1000)
        );
        // localforage.clear(function(err) {
        //   // Run this code once the database has been entirely deleted.
        //   console.log("Database is now empty.");
        // });
        ReactDOM.render(
            <Provider store={store}>
                {/* <Auth0Provider
                    domain="dev-sy0bcy5e.eu.auth0.com"
                    clientId="U0uDhmL5uiSpaCoDfhs3zpPl4NznAKBW"
                    redirectUri={"https://tr.riskplus.net/tr"}
                    // redirectUri={"http://localhost:3000/tr"}
                > */}
                    {/* <App /> */}
                    <RiskScenario />
                {/* </Auth0Provider> */}
            </Provider>,
            document.getElementById("root")
        );
    })
    .catch((err) => {
        console.log(err);
        ReactDOM.render(<div>Failed to initialize data!</div>, document.getElementById("root"));
    });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
