import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme)=>({
    root: {
        flexGrow: 1,
        color: '#d9d9d9',
        paddingTop:'9%'
    },
    title: {
        fontSize: 24,
        marginTop: "2%",
    },
    subTitle: {
        fontSize: 32,
        marginBottom: "10px",
        color: '#f7e53b',
        fontWeight: 'bold',
    },
}));

const barColors = ['#C0504D', '#5785cf'];

export default function Dashboard(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
                <Typography className={classes.title} gutterBottom>
                    Operational Value at Risk (OpVaR)
                </Typography>
                <Typography className={classes.subTitle}>
                    {Math.round(props.data?.opVaR).toLocaleString() || 0}
                </Typography>
            </Grid>
            </Grid>
        </div>
    );
}
