import React, {useEffect, useState} from 'react';
import { DataGrid,  GridToolbarContainer, GridToolbarExport, setGridPageStateUpdate } from '@material-ui/data-grid';
import { makeStyles } from "@material-ui/core/styles";
import {updateCellValue} from '../ducks/inputs'
import { useDispatch, useSelector } from "react-redux";
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import {v1} from 'uuid';
import { Button, Tooltip, TextField } from "@material-ui/core";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "./Alert";
import SettingDialog from './SettingDialog';
import Help from './Help';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      paddingLeft:"3%",
      paddingRight:"3%",
      color:'#d9d9d9',
      border: 'none',
      fontSize:"11px"
    },
    icon:{
      cursor:'pointer',
      width:"20px"
    },
    btn: {
      color: "#999999",
      fontSize:"10px"
    },
    toolbar:{
      height: "30px"
    }
  }));

export default function EditableTable(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const rows = useSelector((state) => state?.inputs?.values) || props.data;

  const sampleData = useSelector((state) => state?.inputs?.values);
  const [data, setData] = useState(sampleData);
  const [file, setFile] = useState();
  const [errorMsg, setErrorMsg] = useState(null);

  //Setting Dialog
  const [open, setOpen] = React.useState(false);
  const [helpOpen, setHelpOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleHelpClickOpen = () => {
    setHelpOpen(true);
  };

  const handleHelpClose = (value) => {
    setHelpOpen(false);
  };

  const columns = [
    {
        headerName: "\u270E Name",
        field: "Name",
        editable: true,
        sortable: false,
        ColumnResizeIcon:true,
        flex: 2,
    },
    {
      headerName: "\u270E Likelihood",
      field: "LH",
      type: "number",
      editable: true,
      sortable: false,
      flex:1.5,
      valueFormatter: (params) => {
        const valueFormatted = (params.value*100).toFixed(1)+ '%';
        return valueFormatted;
      },
    },
    {
        headerName: "\u270E Typical Impact",
        field: "TI",
        type: "number",
        editable: true,
        sortable: false,
        flex:1.5,
        valueFormatter: (params) => {
          const valueFormatted = Number(params.value).toLocaleString();
          return valueFormatted;
        },
    },
    {
      headerName: "\u270E Extreme But Plausible Impact",
      field: "EI",
      type: "number",
      editable: true,
      sortable: false,
      flex:1.5,
      valueFormatter: (params) => {
        const valueFormatted = Number(params.value).toLocaleString();
        return valueFormatted;
      }
    },
];

function handleCellChangeCommited(e){
  if(e.field === 'LH' && (e.props.value<0 || e.props.value>1)){
    setErrorMsg({msg:'Likelihood cannot be negative or larger than 1.', severity:'error'});
    dispatch(updateCellValue({rowId:e.id, column: e.field, value:0.01}));
  }
  else {
    dispatch(updateCellValue({rowId:e.id, column: e.field, value:e.props.value}));
    setData(sampleData);
  }
}

function CustomToolbar() {
  return (
    <GridToolbarContainer className={classes.toolbar}>
      <Button className={classes.btn} onClick={handleClickOpen}>
        <SettingsIcon className={classes.icon}/>Generic Inputs
      </Button>
      <Button className={classes.btn} onClick={handleHelpClickOpen}>
        <HelpIcon className={classes.icon} /> Help
      </Button>
    </GridToolbarContainer>
  );
}

  return (
    <div style={{height: 350,  width: '100%' }}>
      <DataGrid
        rows={rows} 
        columns={columns} 
        className={classes.root} 
        pageSize={10} 
        rowHeight={30} 
        autoPageSize={true}
        onEditCellChangeCommitted={handleCellChangeCommited}
        disableColumnMenu={true}
        isCellEditable={(params) => (params.colIndex === 1 && params.rowIndex < 4) || params.row.AR != 0}
        components={{
          Toolbar: CustomToolbar,
        }}
        />
        <Snackbar
              open={errorMsg !== null}
              anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
              }}
              autoHideDuration={5000}
              onClose={() => {
                  setErrorMsg(null);
              }}
          >
            <Alert severity={errorMsg?.severity}>{errorMsg?.msg}</Alert>
          </Snackbar>
          <SettingDialog
            open={open}
            onClose={handleClose}
          />
          <Help open={helpOpen} onClose={handleHelpClose}/>
    </div>
  );
}