import React, { Component, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-apexcharts";

const style = {
    chart: {
      fontFamily: `font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
       "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale`
    },
};

export default function InputChart(props) {
    const {data} = props;
    const options = {
      series: [{
      name: 'Probability',
      type: 'line',
      data: data.map((d) => d.pdf)
    }],
    chart: {
      foreColor: '#d9d9d9'
    },
    markers: {
      size: 5,
      colors: undefined,
      strokeColors: '#798fb0',
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
  },
  colors: ['#798fb0', '#5785cf'],
    dataLabels: {
      enabled: false
    },
    title: {
      text: 'Severity or Impact Distribution from the Inputs',
      align: 'center',
      color: '#d9d9d9',
      style:{
          fontWeight: 'normal',
        }
    },
    xaxis: {
      categories: data.map((d) => d.impact),
      labels: {
        formatter: (val) => { return Math.round(val).toLocaleString()},
      },
    },
    yaxis: {
      title: {
        text: 'Prob. from Lognormal Curve',
      },
      labels: {
          formatter: (val) => { return (val*100).toFixed(5) + '%'},
      },
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'top',
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return (val*100).toFixed(5) + '%'
        }
      }
    }
  };

    return (
        <div className={style.chart}>
            <Chart
                options={options}
                series={options.series}
                height={345}
            />
        </div>
    );
}